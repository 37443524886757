import { RouterProvider, createBrowserRouter} from 'react-router-dom';
import './App.css';
import Rootlayout, { checkpermission, getbupermission } from './pages/layouts/Rootlayout';
import HomepageRedirect from './pages/Homepage';
import Addcustomer from './pages/Addcustomer';
import {Addproducts, AddProduct} from './pages/Addproduct';
import {Addstock, Addstocks } from './pages/Addstock';
import Billinglayout from './pages/layouts/Billinglayout';
import Customerlayout from './pages/layouts/Customerlayout';
import Productlayout from './pages/layouts/Productlayout';
import Stocklayout from './pages/layouts/Stocklayout';
import Createbill from './pages/Createbill';
import Customerhomepage, { loadAllCustomersData, loadVillageNames } from './pages/homepages/Customerhomepage';
import Billhomepage, { allbillsloader, loadCreateBill, loadCreditBillCustomerData, rangebillsloader, singlebillloader, todaybills, workerbills } from './pages/homepages/Billhomepage';
import Producthomepage, { editPGloader, loadAllProductGroupsData, loadAllProductsData, loadProductLayout, singlePGloader } from './pages/homepages/Producthomepage';
import Stockhomepage, { loadAllStocksData, loadStockLayout } from './pages/homepages/Stockhomepage';
import Allcustomers from './pages/Allcustomers';
import Loginpage from './pages/Loginpage';
import Loginlayout from './pages/layouts/Loginlayout';
import LogoutPage from './pages/Logoutpage';
import Allproducts from './pages/Allproducts';
import Allstocks from './pages/Allstocks';
import Allbills from './pages/Allbills';
import Singlebilldetails from './pages/Singlebilldetails';
import Singlecustomerdetails from './pages/Singlecustomerdetails';
import Editbill from './pages/Editbill';
import CreditPayment from './pages/CreditPayment';
import Homelayout from './pages/layouts/Homelayout';
import Homehomepage from './pages/homepages/Homehomepage';
import { useSelector } from 'react-redux';
import Errorboundary from './Errorboundary';
import Casholdcredit from './pages/Casholdcredit';
import React from 'react';
import Monthbills from './pages/Monthbills';
import Workerbills from './pages/Workerbills';
import ProductAnalysis from './pages/StockAnalysis';
import MinimumQuantityReport from './pages/MinimumQuantityReport';
import CreateProductGroup from './pages/CreateProductGroup';
import AllProductGroups from './pages/Allproductgroups';
import SinglePGdetails from './pages/SinglePGdetails';
import EditProductGroup from './pages/EditProductGroup';
import Calculations from './pages/Calculations';
import Errors from './pages/Errors';
import TodayBills from './pages/TodayBills';
import RemarkBillsOC from './pages/RemarkBillsOC';

function App() {
  const sectionlinks = useSelector(state => state.budata.sectionlinks);
  const bupermissions = useSelector(state => state.budata.permissions);

  const router = createBrowserRouter([
    {path: '',
     element: <Rootlayout />,
     errorElement: <Errorboundary />,
     children: [
      {index: true, element: <HomepageRedirect /> },
      {
        path: 'home', 
        element: <Homelayout />,
        children: [
          {index: true, element: <Homehomepage /> }
        ]
      },
      {
        path: 'shopbilling',
        element: <Loginlayout />,
        children: [
          {path: 'login', element: <Loginpage />},
          {path: 'logout', element: <LogoutPage />},
        ]
      },
      checkpermission(sectionlinks, 'bill', '') &&{
        path: 'bill',
        element: <Billinglayout />,
        children: [
          {index: true, element: <Billhomepage />},
          checkpermission(sectionlinks, 'bill', 'create') && {path: 'create',loader: loadCreateBill,  element: <Createbill /> },
          checkpermission(sectionlinks, 'bill', 'getall') && {path: 'getall', loader: getbupermission(bupermissions, ['owner', 'admin']) ? rangebillsloader : allbillsloader, element: <Allbills /> } ,
          checkpermission(sectionlinks, 'bill', 'getall') && {path: 'getone/:billid', loader: singlebillloader ,element: <Singlebilldetails />},
          getbupermission(bupermissions, ['owner', 'admin']) && checkpermission(sectionlinks, 'bill', 'create') && checkpermission(sectionlinks, 'bill', 'getall') && {path: ':billid/edit', loader: singlebillloader, element: <Editbill /> },
          getbupermission(bupermissions, ['owner', 'admin']) && checkpermission(sectionlinks, 'bill', 'creditpayment') && {path: 'creditpayment', loader: loadCreditBillCustomerData, element: <CreditPayment /> },
          getbupermission(bupermissions, ['owner', 'admin']) && checkpermission(sectionlinks, 'bill', 'casholdcredit') && {path: 'casholdcredit', loader: loadCreditBillCustomerData, element: <Casholdcredit />},
          getbupermission(bupermissions, ['owner', 'admin']) && checkpermission(sectionlinks, 'bill', 'getall') && {path: 'getmonthbills', element: <Monthbills />},
          getbupermission(bupermissions, ['owner', 'admin']) && checkpermission(sectionlinks, 'bill', 'getall') && {path: 'today', loader: todaybills, element: <TodayBills />},
          getbupermission(bupermissions, ['owner', 'admin']) && checkpermission(sectionlinks, 'bill', 'getall') && {path: 'getworkerbills/:workerid', loader: workerbills, element: <Workerbills />},
          getbupermission(bupermissions, ['admin']) && checkpermission(sectionlinks, 'bill', 'casholdcredit') && {path: 'calculations', element: <Calculations />},
          getbupermission(bupermissions, ['admin']) && checkpermission(sectionlinks, 'bill', 'casholdcredit') && {path: 'errors', element: <Errors />},
        ]
      },
      checkpermission(sectionlinks, 'customer', '') &&{
        path:'customer',
        element: <Customerlayout />,
        children: [
          {index: true, element: <Customerhomepage /> },
          checkpermission(sectionlinks, 'customer', 'add') && {path: 'add', loader: loadVillageNames, element: <Addcustomer /> },
          checkpermission(sectionlinks, 'customer', 'getall') && {path: 'getall', loader: loadAllCustomersData, element: <Allcustomers /> },
          checkpermission(sectionlinks, 'customer', 'getone') && {path: 'getone', loader: loadCreditBillCustomerData, element: <Singlecustomerdetails />},
          checkpermission(sectionlinks, 'customer', 'getremarkbillsoc') && {path: 'getremarkbillsoc', element: <RemarkBillsOC />},
        ]
      },
      checkpermission(sectionlinks, 'product', '') &&{
        path:'product', 
        element: <Productlayout />,
        loader: loadProductLayout,
        id: 'productlayoutloader',
        children: [
          {index: true, element: <Producthomepage /> },
          checkpermission(sectionlinks, 'product', 'addmore') && {path: 'addmore', element: <Addproducts />},
          checkpermission(sectionlinks, 'product', 'addone') && {path: 'addone', element: <AddProduct /> },
          checkpermission(sectionlinks, 'product', 'getall') && {path: 'getall',loader: loadAllProductsData , element: <Allproducts />},
          getbupermission(bupermissions, ['owner', 'admin']) && checkpermission(sectionlinks, 'product', 'createproductgroup') && {path: 'createproductgroup', loader: loadStockLayout, element: <CreateProductGroup />},
          getbupermission(bupermissions, ['owner', 'admin']) && checkpermission(sectionlinks, 'product', 'createproductgroup') && {path: 'getproductgroups', loader: loadAllProductGroupsData, element: <AllProductGroups />},
          getbupermission(bupermissions, ['owner', 'admin']) && checkpermission(sectionlinks, 'product', 'createproductgroup') && {path: 'productgroup/:pgid', loader: singlePGloader, element: <SinglePGdetails /> },
          getbupermission(bupermissions, ['owner', 'admin']) && checkpermission(sectionlinks, 'product', 'createproductgroup') && {path: 'productgroup/:pgid/edit', loader: editPGloader, element: <EditProductGroup /> },
          getbupermission(bupermissions, ['owner', 'admin']) && checkpermission(sectionlinks, 'product', 'getanalysis') && {path: 'getanalysis', element: <ProductAnalysis /> },
          getbupermission(bupermissions, ['owner', 'admin']) && checkpermission(sectionlinks, 'product', 'getminreport') && {path: 'getminreport', element: <MinimumQuantityReport /> },
        ]
     },
     checkpermission(sectionlinks, 'stock', '') &&{
        path:'stock',
        element: <Stocklayout />,
        loader: loadStockLayout,
        id: 'stocklayoutloader',
        children: [
          {index: true, element: <Stockhomepage /> },
          checkpermission(sectionlinks, 'stock', 'addone') && {path: 'addone', element:  <Addstock  /> },
          checkpermission(sectionlinks, 'stock', 'addmore') && {path: 'addmore', element: <Addstocks /> },
          checkpermission(sectionlinks, 'stock', 'getall') && {path: 'getall', loader: loadAllStocksData,  element: <Allstocks />},
        ]
      }
     ]
    }
  ])

  return (
    <RouterProvider router={router} />
  );
}

export default App;